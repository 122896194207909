<template>
  <div class="page d-flex align-center justify-center">
    <div class="text-center">
      <v-img
        max-width="500"
        :src="require('@/assets/images/404.svg')"
        alt="Not found image"
      />

      <h1 class="my-3">{{ $t("common.pageNotFound") }}</h1>

      <v-btn color="primary" rounded class="px-10" :to="{ name: 'app' }">
        {{ $t("common.home") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.v-btn {
  &::before {
    opacity: 0;
  }
}
</style>
